import React from 'react'
import { graphql } from 'gatsby'
import Container from '@material-ui/core/Container'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import { FiChevronDown } from 'react-icons/fi'
import { ExpansionPanel, ExpansionPanelSummary } from './_style'
import { Section, Header, Body } from 'components'

const FAQ = ({
  data: {
    allContentfulFaQs: { edges }
  }
}) => {
  return (
    <Section mt="5rem">
      <Container maxWidth="md">
        <Header fontSize="2rem">FAQ</Header>
        {edges
          .map(q => (
            <ExpansionPanel key={q.node.id}>
              <ExpansionPanelSummary expandIcon={<FiChevronDown />}>
                <Body m={0} fontWeight="bold">
                  {q.node.question}
                </Body>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Body m={0}>{q.node.answer.answer}</Body>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </Container>
    </Section>
  )
}

export default FAQ

export const query = graphql`
  query MyQuery {
    allContentfulFaQs {
      edges {
        node {
          id
          answer {
            answer
          }
          books {
            id
          }
          question
        }
      }
    }
  }
`
