import styled from 'styled-components'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import { theme } from 'style'

export const ExpansionPanel = styled(MuiExpansionPanel)`
  &&& {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid ${theme.colors.greys.grey10};
    padding: 0.25rem 0;
    &::before {
      background-color: transparent;
    }
  }
`

export const ExpansionPanelSummary = styled(MuiExpansionPanelSummary)`
  &&& {
    > .MuiExpansionPanelSummary-expandIcon {
      color: ${theme.colors.blues.blue40};
    }
  }
`
export default ExpansionPanel
